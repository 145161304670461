// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-fundinfo-js": () => import("./../../../src/templates/fundinfo.js" /* webpackChunkName: "component---src-templates-fundinfo-js" */),
  "component---src-templates-fundlist-js": () => import("./../../../src/templates/fundlist.js" /* webpackChunkName: "component---src-templates-fundlist-js" */),
  "component---src-templates-globalamclist-js": () => import("./../../../src/templates/globalamclist.js" /* webpackChunkName: "component---src-templates-globalamclist-js" */),
  "component---src-templates-globalinfo-js": () => import("./../../../src/templates/globalinfo.js" /* webpackChunkName: "component---src-templates-globalinfo-js" */)
}

